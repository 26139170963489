import { generateAccessoriesThumbImage } from 'utils/image.utils';
import getUniqueListBy from '../getUniqueListBy';

export const cartMergeFunction = (cData: any) => {
  if (cData && cData.cart.contents.nodes.length > 0) {
    const extraDatas: any = [];
    cData.cart.contents.nodes.map((nod: any) => {
      if (nod.extraData.length > 0) {
        nod.extraData.map((eD: any) => {
          if (eD.key === 'composite_data') {
            extraDatas.push(eD);
          }
        });
      }
    });
    const uniqueExtraData = getUniqueListBy(extraDatas, 'value');
    const result: any = [];
    const forResult: any = [];

    cData.cart.contents.nodes.map((nod: any) => {
      if (getProductType(nod.extraData) && getProductType(nod.extraData).includes('contact-lens')) {
        var properName = nod.extraData.filter((extraDataF: any) => extraDataF.key === 'contactLensType')[0].value;
        var name = nod.product.node.name;
        if (properName != 'both') {
          name = name + ' - ' + properName;
        }
        var image = nod.extraData.filter((extraDataF: any) => extraDataF.key === 'imagePath')[0].value;

        result[result.length] = [
          {
            name: name,
            image: image,
            quantity: nod.quantity,
            type: 'contact-lens',
            databaseId: nod.product.node.databaseId,
            key: nod.key,
            price: Number(
              nod.product.node.price.slice(7, nod.product.node.price.length)
            ),
            subTotal: Number(nod.subtotal.slice(7, nod.subtotal.length)),
            subtotalTax: Number(
              nod.subtotalTax.slice(7, nod.subtotalTax.length)
            ),
            extraData: nod.extraData
          }
        ];
      }

      if (nod.extraData.length > 0 && nod.extraData[0].value !== 'Free' && getProductType(nod.extraData) != 'accessories') {
        if (nod.extraData.length > 2) {
          nod.extraData.map((eD: any) => {
            if (eD.key === 'composite_data') {
              uniqueExtraData.map((eDVal: any, a: number) => {
                if (eD.value === eDVal.value) {
                  if (forResult[a]) {
                    forResult[a].push({
                      ...nod,
                      type: 'extradata'
                    });
                  } else {
                    forResult[a] = [{ ...nod, type: 'extradata' }];
                  }
                }
              });
            }
          });
        } else {
          if (nod.extraData.length > 1) {
            nod.extraData.map((eD: any) => {
              if (eD.key === 'composite_data') {
                uniqueExtraData.map((eDVal: any, a: number) => {
                  if (eD.value === eDVal.value) {
                    if (forResult[a]) {
                      forResult[a].push({
                        ...nod,
                        type: 'frameset'
                      });
                    } else {
                      forResult[a] = [{ ...nod, type: 'frameset' }];
                    }
                  }
                });
              }
            });
          }
        }
      } else if (nod.extraData.length === 0 || nod.extraData[0].value === 'Free' || getProductType(nod.extraData) == 'accessories') {

        let images = null;
        if (nod.variation && nod.variation.node.image && nod.variation.node.image.mediaItemUrl) {
          images = nod.variation.node.image.mediaItemUrl;
        } else if (nod.product && nod.product.node.image && nod.product.node.image.mediaItemUrl) {
          images = nod.product.node.image.mediaItemUrl;
        } else {
          images = generateAccessoriesThumbImage(nod.product.node.slug)
        }

        result[result.length] = [
          {
            name: nod.variation
              ? nod.variation.node.name
              : nod.product.node.name,
            slug: nod.product.node.slug,
            image: images,
            quantity: nod.quantity,
            type: 'noextradata',
            databaseId: nod.variation
              ? nod.variation.node.databaseId
              : nod.product.node.databaseId,
            key: nod.key,
            hasVariation: nod.variation ? true : false,
            variationSlug: nod.variation ? nod.variation.node.slug : null,
            price: Number(nod.product.node.price.slice(7, nod.product.node.price.length)),
            subTotal: Number(nod.subtotal.slice(7, nod.subtotal.length)),
            subtotalTax: Number(nod.subtotalTax.slice(7, nod.subtotalTax.length))
          }
        ];
      }
    });

    const basketItem = {
      items: [...forResult, ...result],
      subtotal: cData.cart.subtotal,
      subtotalTax: cData.cart.subtotalTax,
      shippingTotal: cData.cart.shippingTotal,
      discountTotal: cData.cart.discountTotal,
      total: cData.cart.total,
      chosenShippingMethods: cData.cart.chosenShippingMethods
    };
    if (
      result &&
      cData.cart.subtotal &&
      cData.cart.subtotalTax &&
      cData.cart.total &&
      cData.cart.shippingTotal &&
      cData.cart.discountTotal
    ) {
      return basketItem;
    }
  }
};


function getProductType(extraData: any) {
  if (extraData.filter((extraDataF: any) => extraDataF.key === 'productType').length > 0) {
    return extraData.filter((extraDataF: any) => extraDataF.key == 'productType')[0].value;
  }
  return false;
}