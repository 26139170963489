import { AU_BUNDLE_DISCOUNT_RATE, CA_BUNDLE_DISCOUNT_RATE, NZ_BUNDLE_DISCOUNT_RATE } from "constants/bundleDiscount";

export const DISCOUNT_RATES_BY_REGION: Record<any, any> = {
  ca: CA_BUNDLE_DISCOUNT_RATE,
  au: AU_BUNDLE_DISCOUNT_RATE,
  nz: NZ_BUNDLE_DISCOUNT_RATE,
  international: AU_BUNDLE_DISCOUNT_RATE,
  default: AU_BUNDLE_DISCOUNT_RATE
};

export const makeDiscount = (localBasket: any) => {
  //todo: pass region on dispatch
  //@ts-ignore
  const region = window?.next?.router?.locale;

  const discountRate = DISCOUNT_RATES_BY_REGION[region] || DISCOUNT_RATES_BY_REGION.default;

  var getGlasses = getPrescriptionGlassesOnly(localBasket);
  var MostExpensiveGlasses: any;
  var price = 0;
  for (const glasses of getGlasses) {
    if (price < glasses[0].singlePrice) {
      MostExpensiveGlasses = glasses;
      price = glasses[0].singlePrice;
    }
  }

  var discountedGlasses: any = [];

  const otherGlasses = getGlasses.filter(
    (glasses: any) => glasses[0].uuid !== MostExpensiveGlasses[0].uuid
  );

  if (otherGlasses.length > 0) {
    for (const other of otherGlasses) {
      discountedGlasses.push(singleDiscountGlasses(other, true, discountRate));
    }
  }

  return discountedGlasses.push(
    singleDiscountGlasses(MostExpensiveGlasses, false, discountRate)
  );
};

const singleDiscountGlasses = (item: any, force: boolean, discountRate: number) => {
  if (item) {
    const singlePrice = item[0].singlePrice;
    const discountedSinglePrice =
      singlePrice - (singlePrice * discountRate) / 100;
    if (!force && item[0].quantity > 1) {
      item[0].subTotal =
        discountedSinglePrice * (item[0].quantity - 1) + singlePrice;
    } else if (force) {
      item[0].subTotal = discountedSinglePrice * item[0].quantity;
    } else {
      item[0].subTotal = item.price;
    }

    return item;
  }
};

export const getPrescriptionGlassesOnly = (localBasket: any) => {

  var glasses = [];
  for (const item of localBasket) {
    if (item && item[0].type && item[0].type === 'extradata') {
      if (
        item[0].glassesData.purpose !== 'plano') {
        glasses.push(item);
      }
    }
  }
  return glasses;
};

export const getGlassesQuantity = (localBasket: any) => {

  var quantity = 0;
  for (const item of localBasket) {
    //console.log(item[0].type);

    if (item && item[0].type && (item[0].type === 'extradata' || item[0].type === 'dresdengo')) {
      quantity += item[0].quantity;
    }
  }

  //console.log(quantity);
  return quantity;
};


export const getPrescriptionGlassesQuantity = (localBasket: any) => {
  var quantity = 0;
  for (const item of localBasket) {
    if (item && item[0].type && item[0].type === 'extradata') {
      if (
        item[0].glassesData.purpose !== 'plano' && item[0].glassesData.purpose !== 'non-prescription') {
        quantity += item[0].quantity;
      }
    }
  }
  return quantity;
};


export const getMultifocalPrescriptionGlassesQuantity = (localBasket: any) => {
  var quantity = 0;
  for (const item of localBasket) {
    if (item && item[0].type && item[0].type === 'extradata') {
      if (
        item[0].glassesData.purpose == 'multifocal') {
        quantity += item[0].quantity;
      }
    }
  }
  return quantity;
};


export const getPrescriptionItemQuantity = (localBasket: any) => {
  var quantity = 0;
  for (const item of localBasket) {
    if (item && item[0].type && item[0].type === 'extradata') {
      if (
        item[0].glassesData.purpose !== 'plano' && item[0].glassesData.purpose !== 'non-prescription') {
        quantity += item[0].quantity;
      }
    } else if (item[0].type.includes('contact-lens')) {
      quantity += item[0].quantity;
    }
  }
  return quantity;
};