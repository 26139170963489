import { createSlice } from '@reduxjs/toolkit';

var userIdHash;
var activeStep;
var storeUser;

if (typeof window !== 'undefined') {
    userIdHash = JSON.parse(localStorage.getItem('storeUserHash') || 'null');
    storeUser = JSON.parse(localStorage.getItem('storeUser') || 'null');

    activeStep = JSON.parse(localStorage.getItem('storeActiveStep') || '0');
}

export type RegionState = {
    userIdHash: any;
    activeStep: any;
    storeUser: any;
};

const initialState: RegionState = {
    userIdHash: userIdHash,
    activeStep: activeStep,
    storeUser: storeUser,
};

export const regionSlice = createSlice({
    name: 'storeRegisterEvent',
    initialState,
    reducers: {
        setStoreUserIdHash: (state, action) => {
            localStorage.setItem('storeUserHash', JSON.stringify(action.payload));
            state.userIdHash = action.payload;
        },
        setStoreActiveStep: (state, action) => {
            localStorage.setItem('storeActiveStep', JSON.stringify(action.payload));
            state.activeStep = action.payload;
        },
        setStoreUser: (state, action) => {
            localStorage.setItem('storeUser', JSON.stringify(action.payload));
            state.storeUser = action.payload;
        }
    }
});

export const { setStoreUserIdHash, setStoreActiveStep, setStoreUser } =
    regionSlice.actions;
export default regionSlice.reducer;
