import { SVGProps } from 'react';
import IconStar from '../../public/images/svgs/icon_star.svg';
import Image from "next/legacy/image"

const StarAlt = ({
  width = 14,
  height = 14,
  fill = 'white',
  ...otherProps
}: any) => (
  <svg width={width} {...otherProps} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.62467 1.01869C6.75323 0.669741 7.24677 0.669742 7.37533 1.01869L7.95756 2.59889C8.05031 2.85064 8.35973 2.94149 8.57388 2.77985L9.91799 1.76528C10.2148 1.54124 10.63 1.80806 10.5495 2.17112L10.185 3.81524C10.1269 4.07718 10.3381 4.32089 10.6056 4.30069L12.2849 4.17386C12.6557 4.14585 12.8607 4.59478 12.5967 4.85669L11.4012 6.04273C11.2107 6.23169 11.2566 6.55089 11.4926 6.67853L12.9738 7.47972C13.3009 7.65664 13.2307 8.14514 12.867 8.22274L11.22 8.57415C10.9577 8.63014 10.8237 8.92348 10.9532 9.15844L11.7662 10.6333C11.9457 10.9589 11.6225 11.3319 11.2746 11.2006L9.69907 10.6058C9.44807 10.511 9.17678 10.6854 9.15871 10.9531L9.04526 12.6333C9.02021 13.0043 8.54667 13.1434 8.325 12.8448L7.32117 11.4926C7.16124 11.2772 6.83876 11.2772 6.67883 11.4926L5.675 12.8448C5.45333 13.1434 4.97979 13.0043 4.95474 12.6333L4.84129 10.9531C4.82322 10.6854 4.55193 10.511 4.30093 10.6058L2.72542 11.2006C2.37751 11.3319 2.05431 10.9589 2.23383 10.6333L3.04679 9.15844C3.17631 8.92348 3.04235 8.63014 2.77996 8.57415L1.13298 8.22274C0.769293 8.14514 0.699056 7.65664 1.02615 7.47972L2.50741 6.67853C2.7434 6.55089 2.78929 6.23169 2.59882 6.04273L1.40329 4.85669C1.13928 4.59478 1.3443 4.14585 1.71513 4.17386L3.39439 4.30069C3.66192 4.32089 3.87311 4.07718 3.81503 3.81524L3.45051 2.17112C3.37001 1.80806 3.7852 1.54124 4.08201 1.76528L5.42613 2.77985C5.64027 2.94149 5.94969 2.85064 6.04244 2.59888L6.62467 1.01869Z" fill={fill} />
  </svg>
);

export default StarAlt;
