import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    info:null,
    selectedStore:null,
    selectedService:null,
    selectedProvider:null,
    bookingResponse:null,
    selectedTime:null,
    bookingInfo:null,
    currentState:null,
    availability:null,
}

export const bookingSlice:any = createSlice({
  name: 'bookingEvent',
  initialState,
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
    },
    setSelectedStore: (state, action) => {
        state.selectedStore = action.payload;
    },
    setSelectedService: (state, action) => {
        state.selectedService = action.payload;
    },
    setSelectedProvider: (state, action) => {
        state.selectedProvider = action.payload;
    },
    setBookingResponse: (state, action) => {
        state.bookingResponse = action.payload;
    },
    setSelectedTime: (state, action) => {
        state.selectedTime = action.payload;
    },
    setBookingInfo: (state, action) => {
        state.bookingInfo = action.payload;
    },
    setCurrentState: (state, action) => {
        state.currentState = action.payload;
    },
    setAvailability: (state, action) => {
        state.availability = action.payload;
    }
  }
});

export const { setInfo,
    setSelectedStore,
    setSelectedService,
    setSelectedProvider,
    setBookingResponse,
    setSelectedTime,
    setBookingInfo,
    setAvailability,
    setCurrentState } = bookingSlice.actions;

export default bookingSlice.reducer;
