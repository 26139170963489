import { createSlice } from '@reduxjs/toolkit';

let selectedPrescriptionStorage;

if (typeof window !== 'undefined') {
  selectedPrescriptionStorage = JSON.parse(
    localStorage.getItem('selectedPrescription') || 'null'
  );

  if (
    selectedPrescriptionStorage &&
    !Object.keys(selectedPrescriptionStorage).length
  ) {
    selectedPrescriptionStorage = null;
  }
}

export type PrescriptionState = {
  prescriptions: any;
  pds: any;
  selectedPrescription: any;
  pdSelectPrescriptionId: any;
  pdSelectPId: any;
  usePrescriptionButton: boolean;
  getPrescriptions: boolean;
  selectEditedPrescription: any;
  openPrescriptionGuestForm: boolean;
  openPrescriptionSelection: boolean;
  selectBoxPrescription: any;
  isMultifocal: boolean;
  openUploadPrescriptionGuestForm: boolean;
  frameSize: string | null;
  armSize: string | null;
};

const initialState: PrescriptionState = {
  prescriptions: [],
  pds: [],
  selectedPrescription: selectedPrescriptionStorage,
  pdSelectPrescriptionId: null,
  pdSelectPId: null,
  usePrescriptionButton: false,
  getPrescriptions: false,
  selectEditedPrescription: null,
  openPrescriptionGuestForm: false,
  openPrescriptionSelection: false,
  selectBoxPrescription: null,
  isMultifocal: false,
  openUploadPrescriptionGuestForm: false,
  frameSize: null,
  armSize: null
};

export const prescriptionSlice = createSlice({
  name: 'prescriptionEvent',
  initialState,
  reducers: {
    setOpenUploadPrescriptionGuestForm: (state, action) => {
      state.openUploadPrescriptionGuestForm = action.payload;
    },
    setIsMultifocal: (state, action) => {
      state.isMultifocal = action.payload;
    },
    setFrameSize: (state, action) => {
      state.frameSize = action.payload
    },
    setArmSize: (state, action) => {
      state.armSize = action.payload;
    },
    setPrescriptions: (state, action) => {
      state.prescriptions = action.payload;
    },
    setPds: (state, action) => {
      state.pds = action.payload;
    },
    setSelectedPrescription: (state, action) => {
      // if (
      //   state.selectedPrescription &&
      //   state.selectedPrescription?.id === action.payload?.id
      // ) {
      //   return;
      // }

      if (
        (action.payload &&
          (!Object.keys(action.payload).length)) ||
        !action.payload
      ) {
        state.selectedPrescription = null;
        localStorage.removeItem('selectedPrescription');
        return;
      }

      state.selectedPrescription = action.payload;
      localStorage.setItem(
        'selectedPrescription',
        JSON.stringify(action.payload)
      );
    },
    setPdSelectPrescriptionId: (state, action) => {
      state.pdSelectPrescriptionId = action.payload;
    },
    setUsePrescriptionButton: (state, action) => {
      state.usePrescriptionButton = action.payload;
    },
    setPdSelectPId: (state, action) => {
      state.pdSelectPId = action.payload;
    },
    setGetPrescriptions: (state, action) => {
      state.getPrescriptions = action.payload;
    },
    setSelectEditedPrescription: (state, action) => {
      state.selectEditedPrescription = action.payload;
    },
    setOpenPrescriptionGuestForm: (state, action) => {
      state.openPrescriptionGuestForm = action.payload;
    },
    setOpenPrescriptionSelection: (state, action) => {
      state.openPrescriptionSelection = action.payload;
    },
    setSelectBoxPrescription: (state, action) => {
      state.selectBoxPrescription = action.payload;
    }
  }
});
export const {
  setPrescriptions,
  setSelectedPrescription,
  setPdSelectPrescriptionId,
  setUsePrescriptionButton,
  setPds,
  setPdSelectPId,
  setGetPrescriptions,
  setSelectEditedPrescription,
  setOpenPrescriptionGuestForm,
  setOpenPrescriptionSelection,
  setSelectBoxPrescription,
  setIsMultifocal,
  setOpenUploadPrescriptionGuestForm,
  setFrameSize,
  setArmSize
} = prescriptionSlice.actions;

export default prescriptionSlice.reducer;
