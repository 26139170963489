import React, { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
    ADD_TO_CART,
    ADD_TO_CART_DEFAULT,
    REMOVE_CART_ITEM,
} from '../gqlStrings/mutations/addToCart';
import { RootState } from '../redux/store';
import { addToCart, addToCartContactLens, getCart, removeToCart, setPeopleAlsoBought } from 'redux/features/cart/cartSlice';
import axios from 'axios';
import { setLoading, setTotalQuantity, updateBag, updateShoppingCartWithRequest } from 'redux/features/bag/bagSlice';
import { GET_CART } from 'gqlStrings/queries/getCart';
import { logout } from 'redux/features/auth/authSlice';
import { getSession } from 'functions/session';
import { useCheckoutStore } from 'zus/checkoutStore';

export default function Cart() {
    const addToCartData = useSelector((state: RootState) => state.cartEvent.addToCartData);
    const addToCartContactLensData = useSelector((state: RootState) => state.cartEvent.addToCartContactLensData);
    const removeToCartData = useSelector((state: RootState) => state.cartEvent.removeToCartData);
    const updateToCartData = useSelector((state: RootState) => state.cartEvent.updateToCartData);
    const getCartData = useSelector((state: RootState) => state.cartEvent.getCartData);
    const runQueue = useSelector((state: RootState) => state.bagEvent.runQueue);
    const peopleAlsoBought = useSelector((state: RootState) => state.cartEvent.peopleAlsoBought);

    const authToken = useSelector((state: RootState) => state.authEvent.authToken);

    const selectedRegion = useSelector(
        (state: RootState) => state.regionEvent.selectedRegion
    );
    const dispatch = useDispatch();
    const router = useRouter();

    const [addToCartDefaultMutation] = useMutation(ADD_TO_CART_DEFAULT, {
        context: { locale: selectedRegion?.key },
        onCompleted: () => {
            sendCustomerToCartPage();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [addToCartContactLensMutation] = useMutation(ADD_TO_CART_DEFAULT, {
        context: { locale: selectedRegion?.key },
    });

    const [addToCartMutation] = useMutation(ADD_TO_CART, {
        context: { locale: selectedRegion?.key },
        onCompleted: () => {
            sendCustomerToCartPage();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [removeMutation] = useMutation(REMOVE_CART_ITEM, {
        context: { locale: selectedRegion?.key },
        onCompleted: () => {
            sendCustomerToCartPage();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const [getCartMuatation] = useLazyQuery(GET_CART, {
        context: { locale: selectedRegion?.key },
        onCompleted: (getCartData) => {
            if (getCartData.cart !== null) {
                dispatch(updateShoppingCartWithRequest(getCartData));
                dispatch(setLoading(false));
                dispatch(getCart(false))
            }
        },
        onError: (error) => {
            if (error.networkError && error.networkError.name == 'AbortError') {
                // Handle the aborted request case if necessary
            } else {
                // cartError();
            }
        }
    });

    const cartError = async () => {
        dispatch(logout());
        dispatch(
            updateBag({
                items: []
            })
        );
        dispatch(setLoading(true));
        //Only will work for logged in users
        await getCartMuatation();
    }

    const sendCustomerToCartPage = () => {
        if (router.pathname !== '/shopping-cart') {
            router.push('/shopping-cart');
            dispatch(addToCart(null));
            dispatch(removeToCart(null));
        } else {
            dispatch(addToCart(null));
            dispatch(removeToCart(null));
        }
        if (peopleAlsoBought) {
            dispatch(setPeopleAlsoBought(false));
        }
    }

    const handleAddToCart = (item: any) => {
        if (item.type == 'simple') {
            addToCartDefaultMutation(item);
        } else {
            addToCartMutation(item);
        }
    }

    useEffect(() => {
        if ((selectedRegion && getSession()) || (authToken)) {
            axios.get(
                process.env.NEXT_PUBLIC_LARAVEL_URL +
                '/getTotalQuantity',
                {
                    headers: {
                        Authorization: 'Bearer ' + getSession() //the token is a variable which holds the token
                    }
                }
            ).then((response) => {
                dispatch(setTotalQuantity(response.data));
            }).catch(() => {
                dispatch(setTotalQuantity(0));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegion, authToken]);

    const handleContactLensAddToCart = async (contactLensData: any) => {
        addToCartContactLensMutation(contactLensData[0]).then(() => {
            if (contactLensData.length == 2) {
                addToCartContactLensMutation(contactLensData[1]);
            }
            dispatch(addToCartContactLens([]));
            router.push('/shopping-cart');
        });

    }

    useEffect(() => {
        if (addToCartData && Object.keys(addToCartData).length > 0) {
            handleAddToCart(addToCartData);
        }

        if (removeToCartData && Object.keys(removeToCartData).length > 0) {
            removeMutation(removeToCartData);
        }

        if (updateToCartData && Object.keys(updateToCartData).length > 0) {
            handleAddToCart(addToCartData);
        }
        if ((getCartData || !runQueue || runQueue) && (router.pathname == '/shopping-cart' || router.pathname == '/checkout') && !peopleAlsoBought) {
            dispatch(setLoading(true));
            getCartMuatation();
        }

        if (addToCartContactLensData && addToCartContactLensData.length > 0) {
            handleContactLensAddToCart(addToCartContactLensData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addToCartData, updateToCartData, removeToCartData, getCartData, runQueue, addToCartContactLensData, peopleAlsoBought]);

    return <></>;
}
