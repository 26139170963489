const welcomeCampaign: { [key: string]: { code: string, discount: number, slug: string, title: string, price: number } } = {
    'au': {
        'title': 'Welcome25',
        'code': 'WELCOME25',
        'slug': 'welcome25',
        'discount': 25,
        'price': 75
    },
    'nz': {
        'title': 'Welcome25',
        'code': 'WELCOME25',
        'slug': 'welcome25',
        'discount': 25,
        'price': 75
    },
    'ca': {
        'title': 'Welcome25',
        'code': 'WELCOME25',
        'discount': 25,
        'slug': 'welcome25',
        'price': 75
    },
    'international': {
        'title': 'Welcome25',
        'code': 'WELCOME25',
        'discount': 25,
        'slug': 'welcome25',
        'price': 75
    }
}

export const getWelcomeCampaing = (region: string) => {
    return welcomeCampaign[region];
}