import React, { ReactNode } from 'react';
import Link from 'next/link';
interface TextlinkProps {
  children: ReactNode;
  linkTo: string;
  disableHover?: boolean;
  className?: string;
  wrapperClassName?: string;
  onClick?(): void;
  target?: '_blank' | '_self' | '_parent' | '_top'
}
export default function TextLink({
  children,
  linkTo,
  onClick,
  className,
  wrapperClassName,
  target
}: TextlinkProps) {
  return (
    <div
      className={`flex items-center group ${
        wrapperClassName ? wrapperClassName : ''
      }`}>
      <Link prefetch={false} href={`/${linkTo}`} target={target}>

        <span
          onClick={onClick}
          className={`font-sans flex items-center ${
            className ? className : ''
          }`}>
          {children}
        </span>

      </Link>
    </div>
  );
}
