/* eslint-disable @next/next/no-sync-scripts */
import '../styles/globals.css';
import { useEffect, useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
// import axios from 'axios';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { getOriginalLocation } from 'utils/common.utils';
import { client } from '../apolloclient';
import Layout from '../components/Layout';
import { store } from '../redux/store';
import 'nprogress/nprogress.css';
// eslint-disable-next-line import/order
import * as ga from '../lib/ga';
import { getProperAlternate } from 'functions/checkAlternate';
import { GTMScript } from 'lib/gtm';
import GlobalSeoMetaTags from 'components/GlobalSeoMetaTags/GlobalSeoMetaTags';

if (process.browser) {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];

  // @ts-ignore
  window.dataLayer.push({
    originalLocation: getOriginalLocation()
  })
}

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    Router.events.on('routeChangeStart', () => NProgress.start());
    Router.events.on('routeChangeComplete', () => NProgress.done());
    Router.events.on('routeChangeError', () => NProgress.done());
  }, []);

  const router = useRouter();

  useEffect(() => {

    const handleRouteChange = (url: any) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const removeLastSlashOnUrl = (url: any) => {
    if (url.substring(url.length - 1) == '/') {
      url = url.substring(0, url.length - 1);
    }

    return url;
  };

  const canonicalPath = useMemo(() => {
    const [pathWithoutQueryParams] = router.asPath.split('?');

    return pathWithoutQueryParams;
  }, [router.asPath]);

  if (router.pathname.includes('/external') || router.pathname.includes('/partial-payment')) {
    return <Component {...pageProps} />
  }

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Head>
          <title>Dresden Vision</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <meta
            name="description"
            content="Affordable, sustainable, Australian-made eyewear. Distance or reading glasses from $100; multifocals from $250."
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png?v=2"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png?v=2"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png?v=2"
          />
          <link rel="manifest" href="/manifest.json" />
          <link
            rel="mask-icon"
            href="/safari-pinned-tab.svg?v=2"
            color="#000000"
          />
          <link rel="shortcut icon" href="/favicon.ico?v=2" />

          <meta name="apple-mobile-web-app-title" content="Dresden Vision" />
          <meta name="application-name" content="Dresden Vision" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff" />

          <meta property="og:site_name" content="Dresden Vision" />
          {router.locale === 'international' ? (
            <meta
              property="og:url"
              content={`${removeLastSlashOnUrl(
                process.env.NEXT_PUBLIC_SITE_URL + router.asPath
              )}`}
            />
          ) : (
            <meta
              property="og:url"
              content={`${process.env.NEXT_PUBLIC_SITE_URL}${router.locale
                }${removeLastSlashOnUrl(router.asPath)}`}
            />
          )}

          {getProperAlternate('au', removeLastSlashOnUrl(
            canonicalPath.slice(1, canonicalPath.length)
          )) && (
              <meta property="og:locale" content="en_AU" />
            )}

          {getProperAlternate('nz', removeLastSlashOnUrl(
            canonicalPath.slice(1, canonicalPath.length)
          )) && (
              <meta property="og:locale:alternate" content="en_NZ" />
            )}

          {getProperAlternate('ca', removeLastSlashOnUrl(
            canonicalPath.slice(1, canonicalPath.length)
          )) && (
              <meta property="og:locale:alternate" content="en_CA" />
            )}

          <meta
            name="twitter:description"
            content="Affordable, sustainable, Australian-made eyewear. Distance or reading glasses from $100; multifocals from $250."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@dresdenvision" />
          <meta name="twitter:title" content="Dresden Vision" />

          <meta httpEquiv="Permissions-Policy" content="ch-ua-form-factor" />
          <meta name="google" content="notranslate" />

          {/* <meta
            httpEquiv="Content-Security-Policy"
            content="
              default-src * 'self' data:;
              style-src 'self' 'unsafe-inline'; 
              font-src * data: 'unsafe-inline';
              script-src * 'self' 'unsafe-inline' 'unsafe-eval' ;
              connect-src * https://checkout.stripe.com ; 
              frame-src * 'self'  https://checkout.stripe.com ; "
          /> */}
 
          {getProperAlternate('international', removeLastSlashOnUrl(
            canonicalPath.slice(1, canonicalPath.length)
          )) && (
              <link
                rel="alternate"
                hrefLang="en"
                href={`${process.env.NEXT_PUBLIC_SITE_URL
                  }${canonicalPath.substring(1)}`}
              />
            )}

          {getProperAlternate('au', removeLastSlashOnUrl(
            canonicalPath.slice(1, canonicalPath.length)
          )) && (
              <link
                rel="alternate"
                hrefLang="en-AU"
                href={`${process.env.NEXT_PUBLIC_SITE_URL
                  }${'au'}${removeLastSlashOnUrl(canonicalPath)}`}
              />
            )}

          {getProperAlternate('nz', removeLastSlashOnUrl(
            canonicalPath.slice(1, canonicalPath.length)
          )) && (
              <link
                rel="alternate"
                hrefLang="en-NZ"
                href={`${process.env.NEXT_PUBLIC_SITE_URL
                  }${'nz'}${removeLastSlashOnUrl(canonicalPath)}`}
              />
            )}
          {getProperAlternate('ca', removeLastSlashOnUrl(
            canonicalPath.slice(1, canonicalPath.length)
          )) && (
              <link
                rel="alternate"
                hrefLang="en-CA"
                href={`${process.env.NEXT_PUBLIC_SITE_URL
                  }${'ca'}${removeLastSlashOnUrl(canonicalPath)}`}
              />
            )}

          {router.locale === 'international' ? (
            <link
              rel="canonical"
              href={`${process.env.NEXT_PUBLIC_SITE_URL
                }${canonicalPath.substring(1)}`}
            />
          ) : (
            <link
              rel="canonical"
              href={`${process.env.NEXT_PUBLIC_SITE_URL}${router.locale}${canonicalPath !== '/' ? canonicalPath : ''
                }`}
            />
          )}
        </Head>
        <Layout>
          <Component {...pageProps} />
        </Layout>
        <Toaster />
        <GTMScript />
      </Provider>
    </ApolloProvider>
  );
}
export default MyApp;
