import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  fromPromise,
  Observable,
  DefaultOptions
} from '@apollo/client';
import { onError } from '@apollo/link-error';
//import * as Sentry from '@sentry/browser';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'js-cookie';
import { FINGERPRINT_LOGIN_METHOD } from './gqlStrings/mutations/auth';

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_NEXT_APP_API_URL,
  fetch: fetch,
});

const httpLinkCA = new HttpLink({
  uri: process.env.NEXT_PUBLIC_NEXT_APP_API_URL_CA,
  fetch: fetch
});

const httpLinkNZ = new HttpLink({
  uri: process.env.NEXT_PUBLIC_NEXT_APP_API_URL_NZ,
  fetch: fetch
});

const httpLinkCacheql = new HttpLink({
  uri: process.env.NEXT_PUBLIC_CACHEQL_URL,
  fetch: fetch
});

const httpLinkCacheqlCA = new HttpLink({
  uri: process.env.NEXT_PUBLIC_CACHEQL_URL_CA,
  fetch: fetch
});

const httpLinkCacheqlNZ = new HttpLink({
  uri: process.env.NEXT_PUBLIC_CACHEQL_URL_NZ,
  fetch: fetch
});

const laravelUploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_LARAVEL_API_URL,
  fetch: fetch
});

const laravelLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_LARAVEL_API_URL,
  fetch: fetch
});

/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const user = process.browser ? localStorage.getItem('user') : null;
    /* if (user) {
       Sentry.setUser({ email: JSON.parse(user).email });
     } else {
       Sentry.configureScope((scope) => scope.setUser(null));
     }*/
    if (graphQLErrors) {
      let err: any;
      for (err of graphQLErrors) {
        if (
          err.message === 'Expired token' ||
          err.message === 'Unauthenticated.' ||
          err.message === 'Wrong number of segments' ||
          err.message === 'Malformed UTF-8 characters' ||
          err.debugMessage ===
          'invalid-jwt | The iss do not match with this server'
        ) {
          // if (JSON.parse(localStorage.getItem('remember') || 'false')) {
          //   return fromPromise(
          //     getNewToken().then((fingerPrintLogin: any) => {
          //       var region: any;
          //       var regionsArr = [
          //         { key: 'international', value: 'International', iso: 'AU' },
          //         { key: 'au', value: 'Australia', iso: 'AU' },
          //         { key: 'nz', value: 'New Zealand', iso: 'NZ' },
          //         { key: 'ca', value: 'Canada', iso: 'CA' }
          //       ];

          //       if (typeof window !== 'undefined') {
          //         region =
          //           localStorage.getItem('region') === null
          //             ? regionsArr[0]
          //             : JSON.parse(localStorage.getItem('region') || '{}');
          //       }

          //       localStorage.setItem(
          //         'authTokens',
          //         JSON.stringify(fingerPrintLogin.authTokens)
          //       );
          //       localStorage.setItem(
          //         'authToken',
          //         fingerPrintLogin.authTokens.filter(
          //           (token: any) => token.region === region.key
          //         )[0].token
          //       );
          //       localStorage.setItem(
          //         'authwoo-session',
          //         fingerPrintLogin.authTokens.filter(
          //           (token: any) => token.region === region.key
          //         )[0].token
          //       );

          //       return fingerPrintLogin.authToken;
          //     })
          //   )
          //     .filter((value) => Boolean(value))
          //     .flatMap(() => {
          //       return forward(operation);
          //     });
          // } 
          if (JSON.parse(localStorage.getItem('user') || 'false')) {
            return new Observable(() => {
              hardLogout();
            });
          }
        } else {
          if (err.message === 'The iss do not match with this server') {
            localStorage.removeItem('woo-session');
            localStorage.removeItem('woo-session-expiry');
            location.reload();
          }
          if (
            err.message !== 'The iss do not match with this server' &&
            err.message !== 'not_found_fingerprint' &&
            err.message !== 'Invalid email or password.' &&
            err.message !== 'token_not_found'
          ) {
            // Sentry.captureMessage(err.message);
          }
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      //Sentry.captureException(networkError);
    }
  }
);

const wooMiddleware = new ApolloLink((operation, forward) => {
  const session = process.browser ? localStorage.getItem('woo-session') : null;
  const authToken = process.browser ? localStorage.getItem('authToken') : null;
  const fingerprint = process.browser ? localStorage.getItem('d_id') : null;

  const sessionAge: any = process.browser
    ? localStorage.getItem('woo-session-expiry')
    : null;
  const todaysDate = new Date();
  const oneDay = 60 * 60 * 24 * 14 * 1000;
  const olderThan24h =
    new Date(todaysDate).valueOf() - new Date(sessionAge).valueOf() > oneDay;
  if (olderThan24h && process.browser) {
    localStorage.removeItem('woo-session');
    localStorage.removeItem('woo-session-expiry');
  }

  const headers = {};

  if (authToken) {
    Object.assign(headers, { authorization: `Bearer ${authToken}` });
    Object.assign(headers, { 'woocommerce-session': `Session ${authToken}` });
  } else {
    if (session) {
      Object.assign(headers, { 'woocommerce-session': `Session ${session}` });
    }
  }
  operation.setContext(() => ({
    headers: headers
  }));

  return forward(operation);
});

const laravelMiddleware = new ApolloLink((operation, forward) => {
  const authToken = process.browser ? localStorage.getItem('authToken') : null;
  const session = process.browser ? localStorage.getItem('woo-session') : null;

  var region;
  var regionsArr = [
    { key: 'international', value: 'International', iso: 'AU' },
    { key: 'au', value: 'Australia', iso: 'AU' },
    { key: 'nz', value: 'New Zealand', iso: 'NZ' },
    { key: 'ca', value: 'Canada', iso: 'CA' }
  ];
  var isInternational = false;

  if (typeof window !== 'undefined') {
    region =
      localStorage.getItem('region') === null
        ? regionsArr[0]
        : JSON.parse(localStorage.getItem('region') || '{}');

    isInternational = region.key === 'international';
  }

  const headers = {};

  if (authToken) {
    Object.assign(headers, { authorization: `Bearer ${authToken}` });
    Object.assign(headers, { 'woocommerce-session': `Session ${authToken}` });
  } else {
    if (session) {
      Object.assign(headers, { 'woocommerce-session': `Session ${session}` });
    }
  }

  Object.assign(headers, { region: region.iso });
  Object.assign(headers, { bedrock: true });
  Object.assign(headers, { isInternational: isInternational.toString() });


  operation.setContext(() => ({
    headers: headers
  }));

  return forward(operation);
});


const cacheqlMiddleware = new ApolloLink((operation, forward) => {
  const headers = {};
  if (operation.getContext().region) {
    Object.assign(headers, { region: operation.getContext().region });
  }

  operation.setContext(() => ({
    headers: headers
  }));

  return forward(operation);
});
/**
 * Afterware operation
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
const afterware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (!process.browser) {
      return response;
    }
    /**
     * Check for session header and update session in local storage accordingly.
     */
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;
    const session = headers.get('woocommerce-session');
    if (session) {
      // Remove session data if session destroyed.
      if ('false' === session) {
        localStorage.removeItem('woo-session');
        // Update session new data if changed.
      } else if (localStorage.getItem('woo-session') === null || localStorage.getItem('woo-session') === "") {
        localStorage.setItem('woo-session', headers.get('woocommerce-session'));
        // @ts-ignore: Unreachable code error
        localStorage.setItem('woo-session-expiry', new Date());
      }
    }

    return response;
  });
});

const clientSide = typeof window === 'undefined';
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

const client = new ApolloClient({
  ssrMode: clientSide,
  link: ApolloLink.from([
    errorLink,
    ApolloLink.split(
      (operation) => operation.getContext().clientName === 'laravel',
      new ApolloLink((operation) => {
        if (operation.getContext().upload) {
          return laravelMiddleware
            .concat(afterware.concat(laravelUploadLink))
            .request(operation);
        }
        return laravelMiddleware
          .concat(afterware.concat(laravelLink))
          .request(operation);
      }),
      new ApolloLink((operation) => {
        var locale = operation.getContext().locale;
        var clients: any = {
          international: httpLink,
          au: httpLink,
          nz: httpLinkNZ,
          ca: httpLinkCA,
        };
        return wooMiddleware
          .concat(afterware.concat(clients[locale]))
          .request(operation);
      })
    )
  ]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});

const getNewToken = async () => {
  // isTokenExpired(localStorage.getItem('authToken'))
  try {
    const response = await client.mutate({
      mutation: FINGERPRINT_LOGIN_METHOD,
      variables: {
        input: {
          fingerprint: localStorage.getItem('d_id')
        }
      },
      context: { clientName: 'laravel' }
    });
    return response.data.fingerPrintLogin;
  } catch {
    hardLogout();
  }
};

const hardLogout = (): any => {
  if (localStorage.getItem('authToken')) {
    window.location.href = '/logout?message=false';
  }
};

export { client };

export const clientforssr = new ApolloClient({
  ssrMode: clientSide,
  link: httpLink,
  cache: new InMemoryCache()
});

export const clientforssrca = new ApolloClient({
  ssrMode: clientSide,
  link: httpLinkCA,
  cache: new InMemoryCache()
});

export const clientforssrnz = new ApolloClient({
  ssrMode: clientSide,
  link: httpLinkNZ,
  cache: new InMemoryCache()
});

export const clientforssrCacheql = new ApolloClient({
  ssrMode: clientSide,
  link: httpLinkCacheql,
  cache: new InMemoryCache()
});

export const clientforssrCacheqlCA = new ApolloClient({
  ssrMode: clientSide,
  link: httpLinkCacheqlCA,
  cache: new InMemoryCache()
});

export const clientforssrCacheqlNZ = new ApolloClient({
  ssrMode: clientSide,
  link: httpLinkCacheqlNZ,
  cache: new InMemoryCache()
});
