export const getSession = () => {
    const session = process.browser ? localStorage.getItem('woo-session') : null;
    const authToken = process.browser ? localStorage.getItem('authToken') : null;
    const guestAuthTokens = process.browser ? localStorage.getItem('guestAuthTokens') : null;
    const region = process.browser ? localStorage.getItem('region') : null;

    if (authToken) {
        return authToken;
    } else if (session && guestAuthTokens != null && region != null) {
        try {
            const sessionObj = JSON.parse(guestAuthTokens);
            const getRegion = JSON.parse(region);
            return sessionObj.filter((token: any) => token.region === getRegion.key)[0].token;
        } catch (e) {
            return session;
        }
    } else {
        return null;
    }
}