export const capitalize = (s: string) => {
  if (s) {
    return s[0].toUpperCase() + s.slice(1);
  }
  return s;
};

export const titleCase = (str: string) => {
  if (str == null) {
    return '';
  }
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const discountTitleCase = (str: string) => {
  if (str == null) {
    return '';
  }
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

