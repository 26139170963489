export const getProductType = (item: any) => {
    //console.log('getProductType item', item)
    if(item[0].product.node.name == 'Dresden Glasses'){
        return 'extradata';
    }
    if(item[0].product.node.name == 'DresdenGO Glasses'){
        return 'dresdengo';
    }
    
    if(item.length == 3 && item[0].type == 'frameset'){
        return 'frameset'
    }

    return item[0].type;
}