import { stringToSlug } from 'functions/slug';


//enum bgg = Background Grey, bgw = Background White
export enum BackgroundColor {
  // eslint-disable-next-line no-unused-vars
  Grey = "bgg",
  // eslint-disable-next-line no-unused-vars
  White = "bgw",
}

export const generateGlassesImage = (
  frameColour: string | null,
  armColour: string | null,
  imageIndex: string,
  lensColor: string,
  bgColor?: BackgroundColor
) => {
  // const frameColourSlug = stringToSlug(frameColour);
  // const armColourSlug = stringToSlug(armColour);
  if (!bgColor) {
    bgColor = BackgroundColor.Grey;
  }

  return `https://w.dresden.vision/img/glasses/${lensColor}/${frameColour}/dresden_${frameColour}_${armColour}_${lensColor}_${imageIndex}.png?${bgColor}`;
};
/*
export const generateGlassesImage = (
  frameColour: string,
  armColour: string,
  imageIndex: string,
  lensColor: string,
  useV2Image: boolean = true,
) => {
  const frameColourSlug = stringToSlug(frameColour);
  const armColourSlug = stringToSlug(armColour);
  const imageVersion = useV2Image ? 'v2/' : '';
  const filePath = imageVersion == '' ? 'jpg' : 'webp';
  
  return `https://cdn.dresden.vision/images/frames/${imageVersion}dresden_${frameColourSlug}_${armColourSlug}_${lensColor}_${imageIndex}_sm.${filePath}`;
};
*/

export const generateGlassesThumbImage = (
  frameColour: string,
  armColour: string,
  imageIndex: string,
  lensColor: string,
  bgColor?: BackgroundColor
) => {
  const frameColourSlug = stringToSlug(frameColour);
  const armColourSlug = stringToSlug(armColour);

  return generateGlassesImage(frameColourSlug, armColourSlug, imageIndex, lensColor, bgColor);
  /*
  const imageVersion = useV2Image ? 'v2/' : '';
  const filePath = imageVersion == '' ? 'jpg' : 'webp';

  return `https://cdn.dresden.vision/images/frames/${imageVersion}thumbs/dresden_${frameColourSlug}_${armColourSlug}_${lensColor}_${imageIndex}_sm.${filePath}`;*/
};

export const generateGlassesCategoryThumbImage = (
  colourSlug: string,
  categorySlug: string
) => {

  const lensColour = categorySlug.includes("sunglasses")?'grey':'clear';

  return [ generateGlassesThumbImage(colourSlug, colourSlug, "0", lensColour), 
  generateGlassesThumbImage(colourSlug, colourSlug, "3", lensColour)];
};

export const generateSparePartImage = (
  title: string,
  bgColor?: BackgroundColor
) => {
  const imageSlug = stringToSlug(title);
  if (!bgColor) {
    bgColor = BackgroundColor.Grey;
  }
  return `https://w.dresden.vision/img/arms/dresden_arms_${imageSlug}.png?${bgColor}`;

  /*
  const imageVersion = useV2Image ? 'v2/' : '';
  const filePath = imageVersion == '' ? 'jpg' : 'webp';
  return `https://cdn.dresden.vision/images/arms/${imageVersion}dresden_arms_${imageSlug}.${filePath}`;*/
};

export const generateSparePartThumbImage = (
  title: string,
  bgColor?: BackgroundColor
) => {
  return generateSparePartImage(title, bgColor)
};

export const generateAccessoriesImage = (
  title: string,
  bgColor?: BackgroundColor
) => {
  var imageSlug = stringToSlug(title);
  if (!bgColor) {
    bgColor = BackgroundColor.Grey;
  }
  //return `https://cdn.dresden.vision/images/accessories/${imageVersion}${imageSlug}.jpg`;
  return `https://w.dresden.vision/img/accessories/${imageSlug}.png?${bgColor}`;
};

export const generateAccessoriesImageMultiple = (
  title: string,
  bgColor?: BackgroundColor,
  imageVersion?: string
) => {
  var imageSlug = stringToSlug(title);
  if (!bgColor) {
    bgColor = BackgroundColor.Grey;
  }
  //return `https://cdn.dresden.vision/images/accessories/${imageVersion}${imageSlug}.jpg`;
  return `https://w.dresden.vision/img/accessories/${imageSlug}-${imageVersion}.png?${bgColor}`;
};

export const generateAccessoriesThumbImage = (
  title: string,
  bgColor?: BackgroundColor
) => {
  return generateAccessoriesImage(title,bgColor)
};

export const getContactLensesImageV2 = (
  product: any
) => {
  if(product?.meta && product?.meta.product_images && product?.meta.product_images.length > 0) {
    return product?.meta.product_images[0];
  } 
  else {
    return 'https://cdn.dresden.vision/images/logo-white.png';
  }
};
export const getContactLensesImage = (
  product: any,
  variation: any,
) => {
  if(variation && variation?.productVariantImage) return `https://cdn.dresden.vision/images${variation?.productVariantImage?.imagePath}`;
  if(product && product?.productImageList?.productImages?.[0]?.imagePath) return `https://cdn.dresden.vision/images${product?.productImageList?.productImages?.[0]?.imagePath}`;
  return 'https://cdn.dresden.vision/images/logo-white.png';
};

export const getMultipleImages = (productImageList:any) => {
  const imageList = productImageList?.productImages?.map((item:any) => 'https://cdn.dresden.vision/images'+item.imagePath) 
  return imageList?.length > 0 ? imageList : ['https://cdn.dresden.vision/images/logo-white.png']
}

export const generateMainPageCategoryImage = (
  title: string,
  locale = 'au',
  bgColor?: BackgroundColor
) => {
  const imageSlug = stringToSlug(title);

  if (!bgColor) {
    bgColor = BackgroundColor.Grey;
  }
  return `https://w.dresden.vision/img/categories/mainpage/${imageSlug}.png?${bgColor}`;
}
export const generateImageW = (
  input: string,
  directory: string | 'categories',
  inputType: 'title' | 'slug',
  imageFor: 'accessories' | 'frames' | 'arms' | 'banner' | 'icons',
  fileFormat: 'jpg' | 'png',
  imageType?: 'normal' | 'thumbs',
): string => {

  if (inputType === 'slug' && imageFor === 'banner') {
    return `https://cdn.dresden.vision/img/${directory}/${input}.${fileFormat}`;
  }

  if (imageType === 'thumbs') {
    return `https://cdn.dresden.vision/images/thumbs/${directory}/${input}.${fileFormat}`;
  }

  return `https://cdn.dresden.vision/img/${directory}/${input}.${fileFormat}`;
}

export const generateImage = (
  input: string,
  directory: string | 'categories',
  inputType: 'title' | 'slug',
  imageFor: 'accessories' | 'frames' | 'arms' | 'banner' | 'icons',
  fileFormat: 'jpg' | 'png',
  imageType?: 'normal' | 'thumbs',
): string => {

  if (inputType === 'slug' && imageFor === 'banner') {
    return `https://cdn.dresden.vision/images/${directory}/${input}.${fileFormat}`;
  }

  if (imageType === 'thumbs') {
    return `https://cdn.dresden.vision/images/thumbs/${directory}/${input}.${fileFormat}`;
  }

  return `https://cdn.dresden.vision/images/${directory}/${input}.${fileFormat}`;
}


export const generateWTBImage = (
  frameColorSlug: string,
  armColorSlug: string,
  lensColorSlug: string,
  glassesType : string
) => {
  //return `https://cdn.dresden.vision/images/gokits-vision/dresdengo_kit_clear-frost_green_grey.png`;
  const extension = '.png';
/*
  if( (frameColorSlug == 'koala-grey') && (lensColorSlug == 'grey' || lensColorSlug == 'brown' || lensColorSlug == 'clear')){
    extension = '.jpg'
  }*/

  if(glassesType == 'reading-glasses') {
    return `https://w.dresden.vision/images/gokits/dresdengo_kit_${frameColorSlug}_red_reading.png?bgw`;
  }
  if(glassesType == 'sunglasses') {
    return `https://w.dresden.vision/images/gokits/dresdengo_kit_${frameColorSlug}_green_${lensColorSlug.replaceAll('-','')}${extension}`;
  }
  return `https://w.dresden.vision/images/gokits/dresdengo_kit_${frameColorSlug}_blue_${lensColorSlug.replaceAll('-','')}${extension}`;
}