import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

var region;
var regionSelection;
var country;
var regionsArr = [
  { key: 'international', value: 'International', iso: 'AU' },
  { key: 'au', value: 'Australia', iso: 'AU' },
  { key: 'ca', value: 'Canada', iso: 'CA' },
  { key: 'nz', value: 'New Zealand', iso: 'NZ' }
];

if (typeof window !== 'undefined') {
  region = JSON.parse(localStorage.getItem('region') || 'null');
  regionSelection = JSON.parse(
    localStorage.getItem('regionSelection') || 'false'
  );

  try {
    country = JSON.parse(localStorage.getItem('country') || 'null');
  } catch (e) {
    //try to get country again
    let cookieCountry = Cookies.get('ucountry');
    if (cookieCountry) {
      country = cookieCountry;
    } else {
      country = 'AU';
    }
    localStorage.setItem('country', JSON.stringify(country));
  }
}

export type RegionState = {
  selectedRegion: any;
  regions: any;
  regionSelection: boolean;
  navbarRedirect: boolean;
  country: any;
};

const initialState: RegionState = {
  selectedRegion: region,
  regions: regionsArr,
  regionSelection: regionSelection,
  navbarRedirect: false,
  country: country
};

export const regionSlice = createSlice({
  name: 'addressEvent',
  initialState,
  reducers: {
    setSelectedRegion: (state, action) => {
      localStorage.setItem('region', JSON.stringify(action.payload));
      state.selectedRegion = action.payload;
    },
    setRegionSelection: (state, action) => {
      localStorage.setItem('regionSelection', JSON.stringify(action.payload));
      state.regionSelection = action.payload;
    },
    setNavbarRedirect: (state, action) => {
      state.navbarRedirect = action.payload;
    },
    setCountry: (state, action) => {
      localStorage.setItem('country', JSON.stringify(action.payload));
      state.country = action.payload;
    }
  }
});

export const { setSelectedRegion, setRegionSelection, setNavbarRedirect, setCountry } =
  regionSlice.actions;

export default regionSlice.reducer;
