import { SVGProps } from 'react';

const DoublePage = ({
  width = 16,
  height = 21,
  fill = '#2A2A2A',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (

    <svg width={width} height={height} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
        <path 
            d="M13.3773 6.68348H10.7567V2.54445C10.7567 1.84405 10.462 1.20767 9.987 0.746718C9.51208 0.285959 8.85598 0 8.134 0H2.62268C1.90074 0 1.2448 0.285936 0.769676 0.746718C0.294751 1.20748 0 1.84401 0 2.54445V11.7721C0 12.4725 0.295352 13.1089 0.769676 13.5698C1.2446 14.0306 1.9007 14.3165 2.62268 14.3165H5.24332V18.4555C5.24332 19.156 5.53805 19.7923 6.013 20.2533C6.48792 20.714 7.14402 21 7.866 21H13.3773C14.0993 21 14.7552 20.7141 15.2303 20.2533C15.7052 19.7925 16 19.156 16 18.4555V9.22793C16 8.52752 15.7053 7.89115 15.2303 7.43019C14.7554 6.96943 14.0993 6.68348 13.3773 6.68348ZM9.15718 6.68348H7.866C7.14406 6.68348 6.48812 6.96941 6.013 7.43019C5.53807 7.89095 5.24332 8.52748 5.24332 9.22793L5.19557 12.8303H2.81613C2.49857 12.8303 2.20933 12.7037 2 12.5C1.79006 12.2963 1.6596 12.0157 1.6596 11.7082V2.79241C1.6596 2.48432 1.79006 2.20371 2 2C2.20994 1.79632 2.49919 1.66975 2.81613 1.66975H8C8.31757 1.66975 8.6068 1.79632 8.81677 2C9.02671 2.20368 9.15718 2.4843 9.15718 2.79241V6.68348ZM9.90692 8.5H13.265C13.5826 8.5 13.8718 8.62658 14.0811 8.83025C14.2911 9.03393 14.4215 9.31456 14.4215 9.62266L14.3404 18.3146C14.3404 18.6227 14.2099 18.9033 14 19.107C13.7901 19.3107 13.5008 19.4373 13.1832 19.4373H8C7.68243 19.4373 7.3932 19.3107 7.18323 19.107C6.97329 18.9034 6.84282 18.6227 6.84282 18.3146V9.62266C6.84282 9.31457 6.97329 9.03396 7.18323 8.83025C7.39316 8.62658 7.68242 8.5 8 8.5H9.90692Z" 
            fill={fill}
        />
    </svg>
);

export default DoublePage;
