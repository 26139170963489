// log the pageview with their URL
export const pageview = (url) => {
  window.dataLayer.push({
    event: 'Virtual Pageview',
    page: {
      path: url,
      title: document.title
    }
  })
};

// log specific events happening.
export const event = ({ action, params }) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', action, params);
  }
};

// log specific events happening.
export const addDataLayer = (params, ecommerce = true) => {  
  if (ecommerce) {
    window.dataLayer.push({ ecommerce: null });
  }
  window.dataLayer.push(params);
};


export const sendUICustomEvent = ({eventCategory, eventAction, eventLabel}) => {
  addDataLayer({
    event: 'UICustomEvent',
    eventCategory,
    eventAction,
    eventLabel
  }, false);
};