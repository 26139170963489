import gql from 'graphql-tag';
import { cartString } from '../cartString';

export const CREATE_CART = gql`
  mutation createCart($input: createCartInput!) {
    createCart(input: $input) {
      message
    }
  }
`;
export const CREATE_USER_CART = gql`
  mutation createCartUser($input: createCartInput!) {
    createUserCart(input: $input) {
      message
    }
  }
`;

export const ADD_TO_CART = gql`
  mutation AddToCartMutation($input: AddToCartCompositeProductInput!) {
    addToCartCompositeProduct(input: $input) {
      clientMutationId
    }
  }
`;

export const ADD_TO_ITEMS_CART = gql`
  mutation AddToCartMutation($input: AddAllItemsToCartCompositeProductInput!) {
    addAllItemsToCartCompositeProduct(input: $input) {
      clientMutationId
    }
  }
`;

export const ADD_TO_CART_DEFAULT = gql`
  mutation MyMutation($input: AddToCartInput!) {
    addToCart(input: $input) {
      clientMutationId
    }
  }
`;

export const REMOVE_CART_ITEM = gql`
  mutation RemoveMutation(
    $input: RemoveItemsFromCartInput = { clientMutationId: "", keys: "" }
  ) {
    removeItemsFromCart(input: $input) {
      clientMutationId
      ${cartString}
    }
  }
`;

export const REMOVE_CART_ITEM_ALL = gql`
  mutation RemoveMutationAll($input: AddToCartCompositeProductInput!) {
    addToCartCompositeProduct(input: $input) {
      cart {
        isEmpty
      }
    }
  }
`;

export const UPDATE_QUANTITY_CART = gql`
  mutation MyMutation($input: UpdateQuantityCompositeItemInCartInput!) {
    updateQuantityCompositeItemInCart(input: $input) {
      ${cartString}
    }
  }
`;

export const UPDATE_CART_SIMPLE_QUANTITY = gql`
  mutation MyMutation(
    $items: [CartItemQuantityInput]
    $clientMutationId: String
  ) {
    updateItemQuantities(
      input: { items: $items, clientMutationId: $clientMutationId }
    ) {
      ${cartString}
    }
  }
`;
