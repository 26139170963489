import { FC, Ref, useRef } from 'react';
import { Offset } from 'react-tooltip';
import dynamic from 'next/dynamic';
//import ReactTooltip from 'react-tooltip';
const ReactTooltip = dynamic(
  () => import('react-tooltip')
);
import CrossMarkAlt from 'components/Icons/CrossMarkAlt';
import React from 'react';
import useDeviceType from 'hooks/useDeviceType';

interface UITooltipProps {
  title?: string;
  message: JSX.Element | string;
  id: string;
  place?: 'left' | 'top' | 'right' | 'bottom';
  wrapperClassName?: string;
  border?: boolean;
  borderColour?: string;
  textColour?: string;
  backgroundColour?: string;
  messageWrapperClassName?: string;
  tooltipWrapperClassName?: string;
  offset?: Offset;
	delay?: string;
}

const UITooltip: FC<UITooltipProps> = ({
  message,
  id,
  children,
  place = 'left',
  wrapperClassName,
  border,
  borderColour,
  textColour,
  backgroundColour,
  messageWrapperClassName,
  tooltipWrapperClassName,
  offset,
	delay = "200"
}) => {


  // const tooltipEl = useRef<ReactTooltip & { tooltipRef: Ref<HTMLElement> }>(
  //   null!
  // );
  return (
    <React.Fragment>
      <div
        data-tip
        data-for={id}
        data-delay-hide={delay}
        data-scroll-hide="true"
        className={wrapperClassName || ''}>
        {children}
      </div>
      <ReactTooltip
        className={`custom-tooltip max-w-[250px] ${tooltipWrapperClassName}`}
        id={id}
        place={place}
        effect="solid"
        border={border}
        borderColor={borderColour}
        backgroundColor={backgroundColour}
        textColor={textColour}
        // ref={tooltipEl}
        offset={offset}>
      
      <div>
          <div
            className="absolute right-[8px] top-[8px] hidden cursor-pointer mdx:block"
            // onClick={() => {
            //   tooltipEl.current.tooltipRef = null;
            //   ReactTooltip.hide();
            // }}
						>
            <CrossMarkAlt height={10} width={10} fill="#fff" />
          </div>
        </div>
        <div className={`${messageWrapperClassName}`}>{message}</div>
      </ReactTooltip>
    </React.Fragment>
  );
};

export default UITooltip;
