import { SVGProps } from 'react';

const CrossMarkAlt = ({
  width = 12,
  height = 12,
  fill = '#2A2A2A',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    height={height + 'px'}
    width={width + 'px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9497 0.949747C10.5592 0.559223 9.92606 0.559223 9.53553 0.949748L6 4.48528L2.46447 0.949747C2.07394 0.559223 1.44078 0.559223 1.05025 0.949747C0.659729 1.34027 0.659729 1.97344 1.05025 2.36396L4.58579 5.89949L1.05025 9.43503C0.659729 9.82555 0.659728 10.4587 1.05025 10.8492C1.44078 11.2398 2.07394 11.2398 2.46447 10.8492L6 7.31371L9.53553 10.8492C9.92606 11.2398 10.5592 11.2398 10.9497 10.8492C11.3403 10.4587 11.3403 9.82555 10.9497 9.43503L7.41421 5.89949L10.9497 2.36396C11.3403 1.97344 11.3403 1.34027 10.9497 0.949747Z"
      fill={fill}
    />
  </svg>
);

export default CrossMarkAlt;
