import { createSlice } from '@reduxjs/toolkit';

export type AddressState = {
  addresses: any;
  selectedAddress: any;
  openAddressModal: boolean;
  selectEditedAddress: any;
};

const initialState: AddressState = {
  addresses: [],
  selectedAddress: null,
  openAddressModal: false,
  selectEditedAddress: null
};

export const addressSlice = createSlice({
  name: 'addressEvent',
  initialState,
  reducers: {
    setAddresses: (state, action) => {
      state.addresses = action.payload;
    },
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    setOpenAddressModal: (state, action) => {
      state.openAddressModal = action.payload;
    },
    setSelectEditedAddress: (state, action) => {
      state.selectEditedAddress = action.payload;
    }
  }
});

export const {
  setAddresses,
  setSelectedAddress,
  setOpenAddressModal,
  setSelectEditedAddress
} = addressSlice.actions;

export default addressSlice.reducer;
