import gql from 'graphql-tag';
import { cartStringWithRecalculate } from '../cartString';

export const GET_CART_IS_EMPTY = gql`
  query GetCart {
    cart {
      isEmpty
    }
  }
`;

export const GET_CART = gql`
  query GetCart {
  ${cartStringWithRecalculate}
  }
`;
