import { createSlice } from '@reduxjs/toolkit';

var user = {};
var fingerPrint;
var notUser;
var remember;
var authToken;
var authTokens;
var suspect;
var newsletterGuest;
var guestAuthTokens;
var guestAuthToken;

if (typeof window !== 'undefined') {
  user = JSON.parse(localStorage.getItem('user') || '{}');
  fingerPrint = localStorage.getItem('d_id');
  notUser = JSON.parse(localStorage.getItem('NotUser') || 'false');
  remember = JSON.parse(localStorage.getItem('remember') || 'false');
  authToken = localStorage.getItem('authToken') || '';
  authTokens = JSON.parse(localStorage.getItem('authTokens') || '{}');
  suspect = JSON.parse(localStorage.getItem('suspect') || 'false');
  newsletterGuest = JSON.parse(localStorage.getItem('newsletterGuest') || 'false');
  guestAuthTokens = JSON.parse(localStorage.getItem('guestAuthTokens  ') || '{}');
  guestAuthToken = localStorage.getItem('guestAuthToken') || '';
}

export type authState = {
  modalOpen: boolean;
  user: any;
  authToken: any;
  isLogin: boolean;
  fingerPrint: any;
  notUser: any;
  remember: any;
  suspect: any;
  passwordResetModal: any;
  authTokens: any;
  isNavbarBlack: boolean;
  IsAuthModalOpen: boolean;
  newsletterGuest: boolean;
  IsNewsletterModal: boolean;
  shoppingCartLoginModal: boolean;
  shoppingCartLoginToken: string | null;
  shoppingCartLoginEmail: string | null;
  initialAuthTab: 'SIGN_IN' | 'SIGN_UP';
  guestAuthTokens: any;
  guestAuthToken: any;
  isReferralModalOpen: boolean;
};

const initialState: authState = {
  modalOpen: false,
  isNavbarBlack: false,
  notUser: notUser,
  remember: remember,
  user: user,
  authToken: authToken,
  fingerPrint: fingerPrint,
  isLogin: Object.keys(user).length !== 0,
  suspect: suspect,
  passwordResetModal: false,
  authTokens: authTokens,
  IsAuthModalOpen: false,
  newsletterGuest: newsletterGuest,
  IsNewsletterModal: false,
  shoppingCartLoginModal: false,
  shoppingCartLoginToken: null,
  shoppingCartLoginEmail: null,
  initialAuthTab: 'SIGN_IN',
  guestAuthTokens: guestAuthTokens,
  guestAuthToken: guestAuthToken,
  isReferralModalOpen: false,
};

export const authSlice = createSlice({
  name: 'userEvent',
  initialState,
  reducers: {
    setReferralModalOpen: (state, action) => {
      state.isReferralModalOpen = action.payload;
    },
    setGuestAuthTokens: (state, action) => {
      state.guestAuthTokens = action.payload;
      localStorage.setItem('guestAuthTokens', JSON.stringify(action.payload));
    },
    setGuestAuthToken: (state, action) => {
      state.guestAuthToken = action.payload;
      localStorage.setItem('woo-session', action.payload);
      // @ts-ignore: Unreachable code error
      localStorage.setItem('woo-session-expiry', new Date());
    },
    setShoppingCartLoginModal: (state, action) => {
      state.shoppingCartLoginModal = action.payload;
    },
    setShoppingCartLoginToken: (state, action) => {
      state.shoppingCartLoginToken = action.payload;
    },
    setShoppingCartLoginEmail: (state, action) => {
      state.shoppingCartLoginEmail = action.payload;
    },
    openAuth: (state) => {
      state.modalOpen = true;
    },
    setInitialAuthTab: (state, action) => {
      state.initialAuthTab = action.payload;
    },
    setIsAuthModalOpen: (state, action) => {
      state.IsAuthModalOpen = action.payload;
    },
    closeAuth: (state) => {
      state.modalOpen = false;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLogin = true;

      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setAuthTokens: (state, action) => {
      state.authTokens = action.payload;
      localStorage.setItem('authTokens', JSON.stringify(action.payload));
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
      localStorage.setItem('authToken', action.payload);
      localStorage.setItem('woo-session', action.payload);
      // @ts-ignore: Unreachable code error
      localStorage.setItem('woo-session-expiry', new Date());
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    logout: (state) => {
      state.user = {};
      state.isLogin = false;
      state.authToken = '';
      localStorage.removeItem('user');
      localStorage.removeItem('queue');
      localStorage.removeItem('authToken');
      localStorage.removeItem('remember');
      localStorage.removeItem('suspect');
      localStorage.setItem('NotUser', 'true');
      localStorage.removeItem('woo-session');
      localStorage.removeItem('woo-session-expiry');
      localStorage.removeItem('local-basket');
      localStorage.removeItem('selectedPrescription');
      localStorage.removeItem('authTokens');
      localStorage.removeItem('loggedAsCustomer');
      localStorage.removeItem('checkout-store');
      localStorage.removeItem('order');
      localStorage.removeItem('order-items');
      localStorage.removeItem('order-bagItems');
    },
    setFingerPrintToken: (state, action) => {
      state.fingerPrint = action.payload;
      localStorage.setItem('d_id', action.payload);
    },
    setNotUser: (state, action) => {
      state.notUser = action.payload;
      localStorage.setItem('NotUser', action.payload);
    },
    removeNotUser: () => {
      localStorage.removeItem('NotUser');
    },
    setRemember: (state, action) => {
      state.remember = action.payload;
      localStorage.setItem('remember', action.payload);
    },
    setSuspect: (state, action) => {
      state.suspect = action.payload;
      localStorage.setItem('suspect', action.payload);
    },
    setPasswordResetModal: (state, action) => {
      state.passwordResetModal = action.payload;
    },
    setIsNavbarBlack: (state, action) => {
      state.isNavbarBlack = action.payload;
    },
    setNewsletterGuest: (state, action) => {
      state.newsletterGuest = action.payload;
      localStorage.setItem('newsletterGuest', action.payload);
    },
    setIsNewsletterModal: (state, action) => {
      state.IsNewsletterModal = action.payload;
    },
  }
});
export const {
  openAuth,
  setInitialAuthTab,
  closeAuth,
  setUser,
  setAuthToken,
  setAuthTokens,
  setIsLogin,
  logout,
  setFingerPrintToken,
  setNotUser,
  removeNotUser,
  setRemember,
  setSuspect,
  setPasswordResetModal,
  setIsNavbarBlack,
  setIsAuthModalOpen,
  setNewsletterGuest,
  setIsNewsletterModal,
  setShoppingCartLoginModal,
  setShoppingCartLoginToken,
  setShoppingCartLoginEmail,
  setGuestAuthTokens,
  setGuestAuthToken,
  setReferralModalOpen
} = authSlice.actions;

export default authSlice.reducer;
