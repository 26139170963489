import parse from 'html-react-parser';
import { getProductType } from './productType';

const nsbp = String.fromCharCode(160);


const getGlassesLensTitle = (glasses: any) => {
  var lensItem: string = '';
  glasses.map((item: any) => {
    item.extraData.map((eData: any) => {
      if (eData.key === 'composite_data' && item.variation) {
        lensItem = item;
      }
    })
  });
  return lensItem;
}

export const extraDataPrice = (
  nobagel: any,
  addData: any,
  taxIncule: boolean
) => {
  const lensData: any = getGlassesLensTitle(nobagel);
  const productType = getProductType(nobagel);

  if (productType === 'extradata' ||productType === 'dresdengo') {
    if (lensData) {
      const lensPrice = Number(
        (parse as any)(lensData.variation.node.price).split(nsbp)[1]
      ) * addData[0].quantity;
      return parseFloat(lensPrice.toFixed(2));
    } else {
      return 0;
    }
  } else if (productType == 'frameset') {
    const totalPrice = Number((parse as any)(nobagel[0].product.node.price).split(nsbp)[1]) *
      addData[0].quantity;

    return parseFloat(totalPrice.toFixed(2));
  } else {
    const firstPrice = taxIncule
      ? Number((parse as any)(nobagel[1].product.node.price).split(nsbp)[1]) *
      addData[0].quantity
      : Number((parse as any)(nobagel[1].variation.node.price).split(nsbp)[1]) *
      addData[0].quantity;

    const secondPrice = taxIncule
      ? Number((parse as any)(nobagel[2].product.node.price).split(nsbp)[1]) *
      addData[0].quantity
      : Number((parse as any)(nobagel[2].variation.node.price).split(nsbp)[1]) *
      addData[0].quantity;

    const totalPrice = firstPrice + secondPrice;
    return parseFloat(totalPrice.toFixed(2));
  }
};
export const extraDataSinglePrice = (
  nobagel: any,
  addData: any,
  taxIncule: boolean
) => {
  const lensData: any = getGlassesLensTitle(nobagel);
  const productType = getProductType(nobagel);


  if (productType === 'extradata' || productType === 'dresdengo') {
    if (lensData) {
      const lensPrice = Number(
        (parse as any)(lensData.variation.node.price).split(nsbp)[1]
      );
      return parseFloat(lensPrice.toFixed(2));
    } else {
      return 0;
    }
  } else if (productType == 'frameset') {
    const totalPrice = Number((parse as any)(nobagel[0].product.node.price).split(nsbp)[1]);
    return parseFloat(totalPrice.toFixed(2));
  } else {
    const firstPrice = taxIncule
      ? Number((parse as any)(nobagel[1].product.node.price).split(nsbp)[1])
      : Number((parse as any)(nobagel[1].variation.node.price).split(nsbp)[1]);

    const secondPrice = taxIncule
      ? Number((parse as any)(nobagel[2].product.node.price).split(nsbp)[1])
      : Number((parse as any)(nobagel[2].variation.node.price).split(nsbp)[1]);

    const totalPrice = firstPrice + secondPrice;
    return parseFloat(totalPrice.toFixed(2));
  }
};
export const itemSubTotal = (nobagel: any, taxIncule: boolean) => {
  const lensData: any = getGlassesLensTitle(nobagel);
  const productType = getProductType(nobagel);

  if (productType === 'extradata' || productType === 'dresdengo') {
    if (taxIncule) {
      const subtotal = Number((parse as any)(lensData.subtotal).split(nsbp)[1]);
      const subtotalTax = Number((parse as any)(lensData.subtotalTax).split(nsbp)[1]);
      return subtotal + subtotalTax;
    } else {
      return Number((parse as any)(lensData.subtotal).split(nsbp)[1]);
    }
  } else if (productType == 'frameset') {
    let totalSubtotal = 0;
    let totalSubtotalTax = 0;

    totalSubtotal += Number((parse as any)(nobagel[0].subtotal).split(nsbp)[1]);
    totalSubtotalTax += Number((parse as any)(nobagel[0].subtotalTax).split(nsbp)[1]);

    if (taxIncule) {
      return parseFloat((totalSubtotal + totalSubtotalTax).toFixed(2));
    } else {
      return parseFloat(totalSubtotal.toFixed(2));
    }
  }else {
    let totalSubtotal = 0;
    let totalSubtotalTax = 0;

    if (nobagel[2]) {
      totalSubtotal += Number((parse as any)(nobagel[2].subtotal).split(nsbp)[1]);
      totalSubtotalTax += Number((parse as any)(nobagel[2].subtotalTax).split(nsbp)[1]);
    }

    if (nobagel[1]) {
      totalSubtotal += Number((parse as any)(nobagel[1].subtotal).split(nsbp)[1]);
      totalSubtotalTax += Number((parse as any)(nobagel[1].subtotalTax).split(nsbp)[1]);
    }

    if (taxIncule) {
      return parseFloat((totalSubtotal + totalSubtotalTax).toFixed(2));
    } else {
      return parseFloat(totalSubtotal.toFixed(2));
    }
  }
};

export const itemSubTotalTax = (nobagel: any) => {
  const lensData: any = getGlassesLensTitle(nobagel);
  const productType = getProductType(nobagel);

  if (productType === 'extradata') {
    return Number((parse as any)(lensData.subtotalTax).split(nsbp)[1]);
  } else if (productType == 'frameset') {
    let totalSubtotalTax = 0;
    totalSubtotalTax += Number((parse as any)(nobagel[0].subtotalTax).split(nsbp)[1]);
    return parseFloat(totalSubtotalTax.toFixed(2));
  } else {
    let totalSubtotalTax = 0;

    if (nobagel[2]) {
      totalSubtotalTax += Number((parse as any)(nobagel[2].subtotalTax).split(nsbp)[1]);
    }

    if (nobagel[1]) {
      totalSubtotalTax += Number((parse as any)(nobagel[1].subtotalTax).split(nsbp)[1]);
    }

    return parseFloat(totalSubtotalTax.toFixed(2));
  }
};

export const noExtraDataPrice = (nobagel: any, taxIncule: boolean) => {
  return taxIncule
    ? parseFloat(Number(nobagel[0].price * nobagel[0].quantity).toFixed(2))
    : parseFloat(Number(nobagel[0].price * nobagel[0].quantity).toFixed(2));
};

export const noExtraDataSinglePrice = (nobagel: any, taxIncule: boolean) => {
  return taxIncule
    ? parseFloat(Number(nobagel[0].price).toFixed(2))
    : parseFloat(Number(nobagel[0].price).toFixed(2));
};

export const noExtraDataSubTotal = (nobagel: any, taxIncule: boolean) => {
  return taxIncule
    ? nobagel[0].subTotal + nobagel[0].subtotalTax
    : nobagel[0].subTotal;
};

export const totalBundleDiscount = (
  priceTotal: any,
  subtotal: any,
  subtotalTax: any,
  taxIncule: boolean
) => {
  return taxIncule
    ? Number(priceTotal - subtotal).toFixed(2)
    : Number(priceTotal - subtotal + subtotalTax).toFixed(2);
};

export const shippingRateWithTax = (
  total: any,
  tax: any,
  taxIncule: boolean
) => {
  return taxIncule ? Number(total + tax).toFixed() : Number(total).toFixed();
};

export const hasQueue = () => {
  if (typeof window !== 'undefined' && window) {
    const queueData = JSON.parse(localStorage.getItem('queue') || '{}');
    if (Object.keys(queueData).length > 0) {
      return true;
    }
  }
  return false;
};

export const singleDiscount = (
  coupon: any,
  taxIncule: boolean
) => {
  return coupon.discountAmount ? (taxIncule ?
    Number(Number((parse as any)(coupon.discountAmount).split(nsbp)[1]) + Number((parse as any)(coupon.discountTax).split(nsbp)[1])).toFixed(2)
    :
    Number((parse as any)(coupon.discountAmount).split(nsbp)[1]).toFixed(2)) : 0;
};

export const singleDiscountOrderDetail = (
  coupon: any,
  taxIncule: boolean
) => {
  return taxIncule ? Number(coupon.discountAmount) + Number(coupon.discountTax) : Number(coupon.discountAmount);
};

// Price comes in format something like &nsbp;10
export const priceToNumber = (
  price: string | null
) => {

  if (price == null) {
    return '';
  }

  return Number(price);
}