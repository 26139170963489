import { createSlice } from '@reduxjs/toolkit';

export type CartState = {
  getCartData: boolean;
  addToCartData: any;
  removeToCartData: any;
  updateToCartData: any;
  updateQuantityItemData: any;
  addToCartContactLensData: any;
  peopleAlsoBought: boolean;
};

const initialState: CartState = {
  getCartData: false,
  addToCartData: {},
  addToCartContactLensData: [],
  removeToCartData: {},
  updateToCartData: {},
  updateQuantityItemData: {},
  peopleAlsoBought: false
};

export const cartSlice = createSlice({
  name: 'addressEvent',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.addToCartData = action.payload;
    },
    addToCartContactLens: (state, action) => {
      state.addToCartContactLensData = action.payload;
    },
    removeToCart: (state, action) => {
      state.removeToCartData = action.payload;
    },
    updateToCart: (state, action) => {
      state.updateToCartData = action.payload;
    },
    updateQuantityItem: (state, action) => {
      state.updateQuantityItemData = action.payload;
    },
    getCart: (state, action) => {
      state.getCartData = action.payload;
    },
    setPeopleAlsoBought: (state, action) => {
      state.peopleAlsoBought = action.payload;
    }
  }
});

export const {
  addToCart,
  removeToCart,
  updateToCart,
  updateQuantityItem,
  getCart,
  addToCartContactLens,
  setPeopleAlsoBought
} = cartSlice.actions;

export default cartSlice.reducer;
