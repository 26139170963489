/* eslint-disable no-unused-vars */

import { create } from 'zustand'
import { persist } from 'zustand/middleware';

type UserState = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export interface UserAddress {
  address1: string;
  address2: string | null;
  city: string;
  country: string;
  created_at: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  mobile: string;
  note: string;
  postcode: string;
  state: string;
  suburb: string;
  title: string | null;
  type: string;
};

export type CheckoutState = {
  userData: UserState;
  userAddress: UserAddress | undefined;
  shippingInfoComplete: boolean;
  personalInfoComplete: boolean;
  paymentLoading: boolean;
  personalInfoSource: string;
  shippingInfoSelection: string;
  selectedPickupStore: any;
  setSelectedPickupStore: (store: any) => void;
  paymentIntent: any;
  setPaymentIntent: (intent: any) => void;
  updateUser: (user: Partial<UserState>) => void;
  resetUser: () => void;
  updatePersonalInfoComplete: (condition: boolean) => void;
  updateShippingSelection: (option: string) => void;
  setPersonalInfoSource: (source: string) => void;
  setShippingInfoComplete: (option: boolean) => void;
  setUserAddress: (address: UserAddress | undefined) => void;
  setPaymentLoading: (option: boolean) => void;
  clientSecret: any;
  intentId: any;
  setClientSecret: (option: any) => void;
  setIntentId: (option: any) => void;
  partialPaymentUid: string | null;
  setPartialPaymentUid: (option: string | null) => void;
  processingOrder: any;
  setProcessingOrder: (option: any) => void;
};

export const useCheckoutStore = create<CheckoutState>()(persist(
  (set) => ({
    userData: { firstName: '', lastName: '', email: '', phone: '' },
    userAddress: undefined,
    clientSecret: undefined,
    setUserAddress: (address: UserAddress | undefined) => set(() => ({ userAddress: address })),
    selectedPickupStore: undefined,
    setSelectedPickupStore: (store: any) => set(() => ({ selectedPickupStore: store })),
    paymentIntent: undefined,
    intentId: undefined,
    setPaymentIntent: (intent: any) => set(() => ({ paymentIntent: intent })),
    setClientSecret: (secret: any) => set(() => ({ clientSecret: secret })),
    setIntentId: (id: any) => set(() => ({ intentId: id })),
    shippingInfoComplete: false,
    shippingInfoSelection: '',
    paymentLoading: false,
    setPaymentLoading: (option: boolean) => set(() => ({ paymentLoading: option })),
    personalInfoComplete: false,
    personalInfoSource: 'signin',
    updatePersonalInfoComplete: (condition: boolean) => set(() => ({ personalInfoComplete: condition })),
    resetUser: () =>
      set((state) => ({
        ...state,
        personalInfoComplete: false,
        userData: {
          ...state.userData,
          ...{ firstName: '', lastName: '', email: '', phone: '' },
        },
      })),

    setPersonalInfoSource: (source: string) => set(() => ({ personalInfoSource: source })),
    setShippingInfoComplete: (option: boolean) => set(() => ({ shippingInfoComplete: option })),
    updateUser: (user: Partial<UserState>) =>
      set((state) => ({
        ...state,
        personalInfoComplete: true,
        userData: {
          ...state.userData,
          ...user,
        },
      })),
    updateShippingSelection: (option: string) =>
      set((state) => ({
        ...state,
        shippingInfoSelection: option
      })),
    partialPaymentUid: null,
    setPartialPaymentUid: (option: string | null) => set(() => ({ partialPaymentUid: option })),
    processingOrder: null,
    setProcessingOrder: (option: any) => set(() => ({ processingOrder: option })),
  }),
  {
    name: 'checkout-store',
  }
));