import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    calculatedPd:null,
    centerToIris:null,
    headSize:null,
    faceIsTooFar:true,
    faceIsStraight:false,
    showResult:false,
    setPD:false,
    showPdModal:false,
    pdValues:0,
    showWelcomeScreen:true
}
export const pdSlice:any = createSlice({
  name: 'pdEvent',
  initialState,
  reducers: {
    setCalculatePd: (state, action) => {
      state.calculatedPd = action.payload;
    },
    setCalculateCenterToIris: (state, action) => {
      state.centerToIris = action.payload;
    },
    setCalculateHeadSize:(state, action) => {
      state.headSize = action.payload
    },
    setFaceStates:(state, action) => {
      state.faceIsTooFar = action.payload.isTooFar
      state.faceIsStraight = action.payload.straight
      state.pdValues = action.payload.pdValues
    },
    setShowResult:(state, action) => {
      state.showResult = action.payload
    },
    setUseThisPD:(state, action) => {
      state.setPD = action.payload
    },
    setShowModal:(state, action) => {
      state.showPdModal = action.payload
    },
    setShowWelcomeScreen:(state, action) => {
      state.showWelcomeScreen = action.payload
    },
    setPdValues: (state, action) => {
      state.pdValues = action.payload
    }

  }
});

export const { setCalculatePd, setCalculateCenterToIris, setCalculateHeadSize, setFaceStates, setShowResult, setUseThisPD, setShowModal, setShowWelcomeScreen, setPdValues } =
  pdSlice.actions;

export default pdSlice.reducer;
