export const CALL_CENTER_PHONE_NUMBER_BY_REGION: Record<string, string> = {
  au: '(02) 5300 3003',
  international: '(02) 5300 3003',
  nz: '0800 447 111',
  ca: '(647) 424 4288'
};

export const CALL_CENTER_COUNTRY_BY_REGION: Record<string, string> = {
  au: 'Australia',
  ca: 'Canada',
  international: 'Australia',
  nz: 'New Zealand'
};