import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import addressReducer from './features/address/addressSlice';
import authReducer from './features/auth/authSlice';
import bagReducer from './features/bag/bagSlice';
import prescriptionReducer from './features/prescription/prescriptionSlice';
import regionReducer from './features/region/regionSlice';
import pdReducer from './features/pd/pdSlice';
import cartReducer from './features/cart/cartSlice';
import bookingReducer from './features/booking/bookingSlice';
import storeRegisterReducer from './features/storeRegister/storeRegisterSlice';

export const store = configureStore({
  reducer: {
    bagEvent: bagReducer,
    authEvent: authReducer,
    prescriptionEvent: prescriptionReducer,
    addressEvent: addressReducer,
    regionEvent: regionReducer,
    pdEvent: pdReducer,
    cartEvent: cartReducer,
    bookingEvent: bookingReducer,
    storeRegisterEvent: storeRegisterReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
