import bundleDiscountData from '../globalscripts/jsons/bundleDiscount.json';

export const AU_BUNDLE_DISCOUNT_RATE = bundleDiscountData['au'];
export const NZ_BUNDLE_DISCOUNT_RATE = bundleDiscountData['nz'];
export const CA_BUNDLE_DISCOUNT_RATE = bundleDiscountData['ca'];
export const ONE_PAIR_PRESCRIPTION_GLASSES_PRICE_AU = '$75';
export const ONE_PAIR_PRESCRIPTION_GLASSES_PRICE_NZ = '$80';
export const ONE_PAIR_PRESCRIPTION_GLASSES_PRICE_CA = '$75';
export const ONE_PAIR_BLUE_LIGHT_GLASSES_PRICE_AU = '$60';
export const ONE_PAIR_BLUE_LIGHT_GLASSES_PRICE_NZ = '$70';
export const ONE_PAIR_BLUE_LIGHT_GLASSES_PRICE_CA = '$60';
export const ONE_PAIR_PRESCRIPTION_SUNGLASSES_PRICE_AU = '$100';
export const ONE_PAIR_PRESCRIPTION_SUNGLASSES_PRICE_NZ = '$110';
export const ONE_PAIR_PRESCRIPTION_SUNGLASSES_PRICE_CA = '$100';
export const ONE_PAIR_NON_PRESCRIPTION_SUNGLASSES_PRICE_AU = '$60'
export const ONE_PAIR_NON_PRESCRIPTION_SUNGLASSES_PRICE_NZ = '$70'
export const ONE_PAIR_NON_PRESCRIPTION_SUNGLASSES_PRICE_CA = '$60'
export const TWO_PAIR_PRESCRIPTION_GLASSES_PRICE_AU = '$120';
export const TWO_PAIR_PRESCRIPTION_GLASSES_PRICE_NZ = '$128';
export const TWO_PAIR_PRESCRIPTION_GLASSES_PRICE_CA = '$120';
export const TWO_PAIR_PRESCRIPTION_SUNGLASSES_PRICE_AU = '$160';
export const TWO_PAIR_PRESCRIPTION_SUNGLASSES_PRICE_NZ = '$176';
export const TWO_PAIR_PRESCRIPTION_SUNGLASSES_PRICE_CA = '$160';
export const MULTIFOCAL_BUNDLE_SET_PRICE_AU = '$250';
export const MULTIFOCAL_BUNDLE_SET_PRICE_NZ = '$300';
export const MULTIFOCAL_BUNDLE_SET_PRICE_CA = '$250';
export const BUNDLE_DISCOUNT_LABEL = 'Bundle Discount';